import React, { useState } from 'react';
import FLS from '../FooterLinks/FooterLinks.styles';
import S from './FooterSocialLinks.styles';
const FooterSocialLinks = ({ additionalLinks, items = [], title, }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(S.Container, null,
        title && (React.createElement(React.Fragment, null, additionalLinks ? (React.createElement(React.Fragment, null,
            React.createElement(FLS.Title, { onClick: (event) => {
                    event.preventDefault();
                    setIsOpen(!isOpen);
                }, type: "button" },
                title,
                React.createElement(FLS.TitleIconWrapper, null,
                    React.createElement(FLS.TitleIcon, { isOpen: isOpen }))),
            React.createElement(FLS.List, { isOpen: isOpen }, additionalLinks.map((additionalLink) => (React.createElement(FLS.Item, { key: additionalLink.id },
                React.createElement(FLS.Link, { href: additionalLink.url }, additionalLink.text))))))) : (React.createElement(S.Title, null, title)))),
        React.createElement(S.List, null, items.map((item) => (React.createElement(S.Item, { key: item.id },
            React.createElement(S.Link, { href: item.url, target: "_blank", rel: "noopener" },
                React.createElement(S.Icon, { alt: item.text, src: item.icon, loading: "lazy" }))))))));
};
export default FooterSocialLinks;
