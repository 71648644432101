import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled from 'styled-components';
const Container = styled.div ``;
const Title = styled.span `
  display: block;
  background: none;
  border: 0;
  color: #fff;
  ${fonts.DaxPro.Bold};
  font-size: 19px;
  line-height: 28px;
  margin: 0 0 16px;
  padding: 0;
  text-align: center;
  width: 100%;

  @media ${from(Device.Tablet)} {
    cursor: default;
    pointer-events: none;
    text-align: left;
  }
`;
const Address = styled.address `
  display: block;
  color: #fff;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;

  @media ${from(Device.Tablet)} {
    text-align: left;
  }
`;
export default {
    Address,
    Container,
    Title,
};
