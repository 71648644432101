import React from 'react';
import S from './FooterCultureSelector.styles';
const FooterCultureSelector = ({ flag, initialValue, items = [], }) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.SelectWrapper, null,
            flag && React.createElement(S.Flag, { alt: "", src: flag, width: "28", height: "21", loading: "lazy" }),
            React.createElement(S.Select, { id: "culture", name: "culture", tabIndex: -1, "data-ignore": true, value: initialValue, onChange: (event) => {
                    const item = items.find((item) => item.id === event.target.value);
                    if (!item) {
                        return;
                    }
                    window.location.href = `${window.location.origin}/${item.url}/`;
                } }, items.map((item) => (React.createElement("option", { key: item.id, value: item.id }, item.text)))))));
};
export default FooterCultureSelector;
