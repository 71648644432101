import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import styled from 'styled-components';
const Container = styled.div `
  display: block;
  margin-bottom: 16px;

  @media ${from(Device.Tablet)} {
    margin-bottom: 0;
  }
`;
const List = styled.ul `
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  @media ${until(Device.Tablet)} {
    justify-content: center;
  }
`;
const Item = styled.li `
  display: block;
  margin: 0;
  padding: 0;

  & + & {
    border-left: 1px solid #fff;
    margin-left: 16px;
    padding-left: 16px;
  }
`;
const Link = styled.a `
  display: block;
  background: none;
  border: 0;
  color: #fff;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  text-decoration: none;

  &:focus,
  &:hover {
    background: none;
    color: #fff;
    text-decoration: underline;
  }

  @media ${until(Device.Tablet)} {
    font-size: 14px;
    line-height: 18px;
  }
`;
export default {
    Container,
    Item,
    Link,
    List,
};
