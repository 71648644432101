import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import styled from 'styled-components';
import FooterLinksStyles from '../FooterLinks/FooterLinks.styles';
const Container = styled.div `
  @media ${until(Device.Tablet)} {
    margin-bottom: 32px;
  }

  ${FooterLinksStyles.List} {
    margin-bottom: 24px;
  }
`;
const Title = styled.span `
  display: block;
  background: none;
  border: 0;
  color: #fff;
  ${fonts.DaxPro.Bold};
  font-size: 19px;
  line-height: 28px;
  margin: 0 0 16px;
  padding: 0;
  text-align: center;
  width: 100%;

  @media ${from(Device.Tablet)} {
    cursor: default;
    pointer-events: none;
    text-align: left;
  }
`;
const List = styled.ul `
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  @media ${until(Device.Tablet)} {
    justify-content: center;
  }
`;
const Item = styled.li `
  display: block;
  margin: 0;
  padding: 0;

  & + & {
    margin-left: 24px;
  }
`;
const Link = styled.a `
  display: block;
  background: none;
  border: 0;
  color: #fff;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 33px;
  margin: 0;
  padding: 0;
  text-decoration: none;

  &:focus,
  &:hover {
    background: none;
    color: #fff;
    text-decoration: underline;
  }
`;
const Icon = styled.img `
  display: block;
  height: 30px;
  object-fit: contain;
  object-position: center center;
  font-family: 'object-fit: contain; object-position: center center;';
  width: 30px;
`;
export default {
    Container,
    Icon,
    Item,
    Link,
    List,
    Title,
};
