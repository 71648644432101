import SvgSelectArrow from '@img/icons/select-arrow-up.svg';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled from 'styled-components';
const Container = styled.div `
  display: block;
  margin-bottom: 16px;
  text-align: center;

  @media ${from(Device.Tablet)} {
    margin-bottom: 0;
    text-align: left;
  }
`;
const SelectWrapper = styled.div `
  position: relative;
  display: inline-block;
`;
const Flag = styled.img `
  position: absolute;
  top: 50%;
  left: 0;
  pointer-events: none;
  transform: translate(12px, -50%);
`;
const Select = styled.select `
  display: block;
  appearance: none;
  background: #fff url(${SvgSelectArrow}) no-repeat 134px 50%;
  color: #333333;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 20px;
  height: 36px;
  padding: 8px 38px 4px 60px;
  // !important due to a dumb 100% width style on
  // all select elements on mobile...
  width: 160px !important;

  &::-ms-expand {
    display: none;
  }
`;
export default {
    Container,
    Flag,
    Select,
    SelectWrapper,
};
