import { HydrateOption } from '@core/enums';
import withWidget from '@hoc/withWidget';
import React from 'react';
import FooterAddress from './components/FooterAddress/FooterAddress';
import FooterCopyright from './components/FooterCopyright/FooterCopyright';
import FooterCultureSelector from './components/FooterCultureSelector/FooterCultureSelector';
import FooterLegalLinks from './components/FooterLegalLinks/FooterLegalLinks';
import FooterLinks from './components/FooterLinks/FooterLinks';
import FooterSocialLinks from './components/FooterSocialLinks/FooterSocialLinks';
import S from './SiteFooter.styles';
const SiteFooter = ({ address, copyright, cultureSelector, legalLinks, links1, links2, socialLinks, }) => {
    const additionalLinks = (links2?.items ?? []).length > 6 ? links2?.items?.splice(6) : undefined;
    return (React.createElement(S.Container, null,
        React.createElement(S.Top, null,
            React.createElement(S.TopInner, null,
                links1 && React.createElement(FooterLinks, { ...links1 }),
                links2 && React.createElement(FooterLinks, { ...links2 }),
                socialLinks && React.createElement(FooterSocialLinks, { ...socialLinks, additionalLinks: additionalLinks }),
                address && React.createElement(FooterAddress, { ...address }))),
        React.createElement(S.Bottom, null,
            React.createElement(S.BottomInner, null,
                legalLinks && legalLinks.items && legalLinks.items.length > 0 && (React.createElement(FooterLegalLinks, { ...legalLinks })),
                copyright && React.createElement(FooterCopyright, { copyright: copyright }),
                cultureSelector && React.createElement(FooterCultureSelector, { ...cultureSelector })))));
};
export default withWidget(SiteFooter, 'SiteFooter', { hydrate: HydrateOption.InView });
