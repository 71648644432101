import { Device, from, until } from '@helpers/media';
import SiteWide from '@stories/Components/Containers/SiteWide/SiteWide';
import styled from 'styled-components';
import FooterAddressStyles from './components/FooterAddress/FooterAddress.styles';
import FooterCopyrightStyles from './components/FooterCopyright/FooterCopyright.styles';
import FooterCultureSelectorStyles from './components/FooterCultureSelector/FooterCultureSelector.styles';
import FooterLegalLinksStyles from './components/FooterLegalLinks/FooterLegalLinks.styles';
import FooterLinksStyles from './components/FooterLinks/FooterLinks.styles';
import FooterSocialLinksStyles from './components/FooterSocialLinks/FooterSocialLinks.styles';
const Container = styled.footer `
  display: block;
  background: #333333;
  border: 0;
  border-top: 14px solid #6f9d20;
  color: #fff;
  margin: 0;
  padding: 0;
`;
const Top = styled.div ``;
const TopInner = styled(SiteWide) `
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 32px;

  @media ${from(Device.Tablet)} {
    align-items: flex-start;
    flex-direction: row;
    padding-top: 48px;
    padding-bottom: 24px;

    ${FooterLinksStyles.Container},
    ${FooterSocialLinksStyles.Container},
    ${FooterAddressStyles.Container} {
      flex: 0 0 25%;
    }
  }
`;
const Bottom = styled.div ``;
const BottomInner = styled(SiteWide) `
  display: flex;
  border-top: 1px solid #6f9d20;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 48px;

  @media ${until(Device.Tablet)} {
    ${FooterLegalLinksStyles.Container} {
      order: 2;
    }

    ${FooterCopyrightStyles.Container} {
      order: 3;
    }

    ${FooterCultureSelectorStyles.Container} {
      order: 1;
    }
  }

  @media ${from(Device.Tablet)} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 92px;

    ${FooterLegalLinksStyles.Container} {
      flex: 0 0 auto;
    }

    ${FooterCopyrightStyles.Container} {
      flex: 0 0 auto;
    }

    ${FooterCultureSelectorStyles.Container} {
      flex: 0 0 auto;
    }
  }
`;
export default {
    Bottom,
    BottomInner,
    Container,
    Top,
    TopInner,
};
