import React, { useState } from 'react';
import S from './FooterLinks.styles';
const FooterLinks = ({ items = [], title }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(S.Container, null,
        title && (React.createElement(S.Title, { onClick: (event) => {
                event.preventDefault();
                setIsOpen(!isOpen);
            }, type: "button" },
            title,
            React.createElement(S.TitleIconWrapper, null,
                React.createElement(S.TitleIcon, { isOpen: isOpen })))),
        React.createElement(S.List, { isOpen: isOpen }, items.map((item) => (React.createElement(S.Item, { key: item.id },
            React.createElement(S.Link, { href: item.url }, item.text)))))));
};
export default FooterLinks;
