import { fonts } from '@helpers/fonts';
import { Device, until } from '@helpers/media';
import styled from 'styled-components';
const Container = styled.div `
  display: block;
  color: #fff;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  text-align: right;
  text-decoration: none;

  @media ${until(Device.Tablet)} {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
`;
export default {
    Container,
};
