import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { ReactComponent as SvgAccordionChevron } from '@img/icons/accordion-chevron.svg';
import styled, { css } from 'styled-components';
const Container = styled.div `
  display: block;

  @media ${until(Device.Tablet)} {
    margin-bottom: 32px;
  }
`;
const Title = styled.button.attrs({ type: 'button' }) `
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  color: #fff;
  ${fonts.DaxPro.Bold};
  font-size: 19px;
  justify-content: center;
  line-height: 28px;
  margin: 0 0 16px;
  padding: 0;
  text-align: center;
  width: 100%;

  &:focus,
  &:hover {
    background: none;
    color: #fff;
  }

  @media ${from(Device.Tablet)} {
    cursor: default;
    justify-content: flex-start;
    pointer-events: none;
    text-align: left;
  }
`;
const TitleIconWrapper = styled.span `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  @media ${from(Device.Tablet)} {
    display: none;
  }
`;
const TitleIcon = styled(SvgAccordionChevron) `
  display: block;

  ${({ isOpen }) => isOpen
    ? css `
          transform: rotate(180deg);
        `
    : null}
`;
const List = styled.ul `
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  padding: 0;

  @media ${until(Device.Tablet)} {
    ${({ isOpen }) => isOpen
    ? css `
            display: flex;
          `
    : css `
            display: none;
          `}
  }

  @media ${from(Device.Tablet)} {
    align-items: flex-start;
  }
`;
const Item = styled.li `
  display: block;
  margin: 0;
  padding: 0;
`;
const Link = styled.a `
  display: block;
  background: none;
  border: 0;
  color: #fff;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 33px;
  margin: 0;
  padding: 0;
  text-decoration: none;

  &:focus,
  &:hover {
    background: none;
    color: #fff;
    text-decoration: underline;
  }
`;
export default {
    Container,
    Item,
    Link,
    List,
    Title,
    TitleIcon,
    TitleIconWrapper,
};
